let config = {};

const prefix = 'REACT_APP_';

for (const [key, value] of Object.entries(process.env)) {
  if (!key.startsWith(prefix)) {
    continue;
  }
  const confKey = key.replace(prefix, '');
  config[confKey] = value;
}

config = {
  ...config,
  APP_VERSION: '1.13.0',
  APP_TITLE: 'Maverick Signings',
  // number of rows to show in nested lists before displaying a 'View more' button
  COLLAPSED_TBL_ROWS: 6,
  // refresh cookie is available in paths prefixed with this value
  // must match backend value
  AUTH_PATH_PREFIX: '/auth',
  // api urls
  API_BE_URL: config.API_URL + '/be',
  // number of digits required for a valid phone number
  PHONE_NR_OF_DIGITS: 10,
};

export default config;